
import {
  Emit,
  Prop,
  Component,
  Mixins,
  Watch,
  InjectReactive
} from 'vue-property-decorator';
import Vue from 'vue';
import SnackbarMixin from '@/mixins/snackbarMixin';
import { Applicant } from '@/models/Entities/Personas/Applicant';
import ProfileDetailsBaseComponent from './ProfileDetailsBaseComponent.vue';
import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { getSectionStatus } from '@/util/getSectionStatus';
import { ESectionStatus } from '@/enums/ESectionStatus';
import { convertToKeyValueObj } from '@/util/object/convertToKeyValueObj';
import DateSelectorComponent from '@/components/Forms/Elements/DateSelectorComponent.vue';
import DropdownComponent from '@/components/Forms/Elements/DropdownComponent.vue';
import TextInputComponent from '@/components/Forms/Elements/TextInputComponent.vue';
import RadioComponent from '@/components/Forms/Elements/RadioComponent.vue';
import { yesNoOptions } from '@/models/Forms/FormOption';
import generateScrollToCallback from '@/util/generateScrollToCallback';
import UpdateStickyComponent from '@/components/UpdateStickyComponent.vue';
import NumberInputComponent from '@/components/Forms/Elements/NumberInputComponent.vue';
import { getInputOptions } from '@/util/getInputOptions';
import {
  GroupInfo,
  PocType,
  AirForcePrograms,
  SisterServices
} from '@/models/Entities/Student/Constants';
import Radio from '@/components/Forms/Elements/RadioComponent.vue';
import TextInput from '@/components/Forms/Elements/TextInputComponent.vue';
import PhoneInput from '@/components/Forms/Elements/PhoneInputComponent.vue';
import { ApplicantTypes } from '@/models/Entities/Personas/Constants';
import { EGainsPriority } from '@/enums';
import TableDropDownComponent from '@/models/Table/Legacy/Elements/LegacyTableDropDownComponent.vue';
import ReadOnlyComponent from '@/components/Forms/Elements/ReadOnlyComponent.vue';
import { EMedicalClearanceStatus } from '@/enums/EMedicalClearance';

const sections = convertToKeyValueObj({
  PRE_GAIN: 'Pre-Gain',
  POST_GAIN: 'Post-Gain',
  PRIORITY: 'Priority'
});

class Model extends BaseCandidate {
  statusFunction: (fields: unknown[]) => ESectionStatus;

  get preGainSectionStatus(): ESectionStatus {
    return this.statusFunction([
      this.gainsShareddrivefolderdate,
      this.gains1288sentdate,
      this.gainsAf1288received,
      this.gainsMedicalclearancedate,
      this.gainsMedicalClearanceStatus,
      this.gainsMilpdspascodeconfirmationate,
      this.gainsVitalscommcheckdate,
      this.gainsMilpdsgainprojectiondate,
      this.gainsSecuritycheckdate
    ]);
  }

  get postGainSectionStatus(): ESectionStatus {
    return this.statusFunction([
      this.gainsGaindate,
      this.gainsAfrsectioniddate,
      this.gainsDdaformationdate,
      this.gainsSurfdate,
      this.gainsDutytitledate,
      this.gainsCompcatconfirmeddate,
      this.gainsAefbandate,
      this.gainsPostactiondate
    ]);
  }

  constructor(
    partial?: Partial<BaseCandidate>,
    statusFunction = getSectionStatus
  ) {
    super(partial);
    this.statusFunction = statusFunction;
  }
}

@Component<GainsPage>({
  components: {
    DateSelectorComponent,
    DropdownComponent,
    TextInputComponent,
    ProfileDetailsBaseComponent,
    RadioComponent,
    UpdateStickyComponent,
    NumberInputComponent,
    Radio,
    TextInput,
    PhoneInput,
    TableDropDownComponent,
    ReadOnlyComponent
  }
})
export default class GainsPage extends Mixins(Vue, SnackbarMixin) {
  @Prop({ required: true })
  applicant!: Applicant;

  @InjectReactive('isUpdating')
  isUpdating!: boolean;

  model = new Model();
  created(): void {
    this.model = new Model(this.applicant);
  }

  yesNoOptions = yesNoOptions;
  sections = sections;
  PocType = PocType;
  ApplicantTypes = ApplicantTypes;

  groupInfoDropdownOptions = getInputOptions(GroupInfo);
  pocTypeRadioOptions = getInputOptions(PocType);
  activeDutyRadioOptions = getInputOptions(AirForcePrograms);
  sisterServiceOptions = getInputOptions(SisterServices);
  gainsPriorityOptions = getInputOptions(EGainsPriority);
  medicalClearanceOptions = getInputOptions(EMedicalClearanceStatus);

  get isActiveDuty(): boolean {
    return this.applicant.applicantType === ApplicantTypes.ACTIVE_DUTY;
  }
  get isRotc(): boolean {
    return this.applicant.applicantType === ApplicantTypes.ROTC;
  }
  get isTraditionalReservist(): boolean {
    return (
      this.applicant.applicantType === ApplicantTypes.TRADITIONAL_RESERVIST
    );
  }
  get isActiveGuardReserve(): boolean {
    return this.applicant.applicantType === ApplicantTypes.ACTIVE_GUARD_RESERVE;
  }
  get isAirNationalGuard(): boolean {
    return this.applicant.applicantType === ApplicantTypes.AIR_NATIONAL_GUARD;
  }
  get isSisterService(): boolean {
    return this.applicant.applicantType === ApplicantTypes.SISTER_SERVICE;
  }
  get isIMA(): boolean {
    return (
      this.applicant.applicantType ===
      ApplicantTypes.INDIVIDUAL_MOBILIZATION_AUGMENTEE
    );
  }
  get showIRR(): boolean {
    return (
      this.applicant.applicantType &&
      this.applicant.applicantType !==
        ApplicantTypes.INDIVIDUAL_MOBILIZATION_AUGMENTEE
    );
  }

  get disableMedicalClearanceDateEntry(): boolean {
    return (
      this.model.gainsMedicalClearanceStatus !==
        EMedicalClearanceStatus.CERTIFIED &&
      this.model.gainsMedicalClearanceStatus !==
        EMedicalClearanceStatus.DISQUALIFIED
    );
  }
  handleOptionChange(val: EGainsPriority): void {
    this.model.gainsGainspriority = val;
  }

  @Watch('model')
  @Watch('applicant')
  setSectionStatus(): void {
    const { PRE_GAIN, POST_GAIN, PRIORITY } = this.sections;

    this.$store.dispatch('profileSideNavigationModule/setSections', [
      {
        header: 'Gains',
        entries: [
          {
            text: PRE_GAIN.label,
            status: this.model.preGainSectionStatus,
            onClick: generateScrollToCallback(PRE_GAIN.key)
          },
          {
            text: POST_GAIN.label,
            status: this.model.postGainSectionStatus,
            onClick: generateScrollToCallback(POST_GAIN.key)
          },
          {
            text: PRIORITY.label,
            status: ESectionStatus.COMPLETE,
            onClick: generateScrollToCallback(PRIORITY.key)
          }
        ]
      }
    ]);
  }

  @Emit('updateApplicant')
  updateApplicant(): Model {
    return this.model;
  }
}
