var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "profile-details-base-component",
    { staticClass: "mb-5", attrs: { title: "Gains" } },
    [
      _c("div", { staticClass: "flex mb-4 gap-4" }, [
        _c("div", [
          _c("h3", { staticClass: "section-header" }, [_vm._v("EDCSA")]),
          _c(
            "div",
            { staticClass: "mt-2 mb-8" },
            [
              _c("date-selector-component", {
                attrs: { "data-cy": "edcsa" },
                model: {
                  value: _vm.model.gainsEdcsa,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "gainsEdcsa", $$v)
                  },
                  expression: "model.gainsEdcsa",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", [
          _c("h3", { staticClass: "section-header" }, [_vm._v("POS No.")]),
          _c(
            "div",
            { staticClass: "mt-2 mb-8" },
            [
              _c("text-input", {
                staticClass: "mb-6",
                attrs: { placeholder: "Enter position number" },
                model: {
                  value: _vm.model.gainsPositionNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "gainsPositionNumber", $$v)
                  },
                  expression: "model.gainsPositionNumber",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("div", [
        _c("div", [
          _c("h3", { staticClass: "section-header" }, [_vm._v("Group")]),
          _c(
            "div",
            { staticClass: "mb-8 form-input-container" },
            [
              _c("dropdown-component", {
                attrs: { values: _vm.groupInfoDropdownOptions },
                model: {
                  value: _vm.model.gainsGroupinfo,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "gainsGroupinfo", $$v)
                  },
                  expression: "model.gainsGroupinfo",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", [
          _c("h3", { staticClass: "tracking-wide section-header" }, [
            _vm._v("FSS/Recruiter"),
          ]),
          _c(
            "div",
            { staticClass: "mt-2 mb-10" },
            [
              _c("radio", {
                staticClass: "w-1/2 mb-6",
                attrs: { radioItems: _vm.pocTypeRadioOptions },
                model: {
                  value: _vm.model.gainsPoctype,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "gainsPoctype", $$v)
                  },
                  expression: "model.gainsPoctype",
                },
              }),
              _vm.model.gainsPoctype === _vm.PocType.FSS
                ? _c(
                    "div",
                    { key: "FSS", staticClass: "form-input-container" },
                    [
                      _c("text-input", {
                        staticClass: "mb-6",
                        attrs: { label: "FSS POC" },
                        model: {
                          value: _vm.model.gainsIncomingfsspoc,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "gainsIncomingfsspoc", $$v)
                          },
                          expression: "model.gainsIncomingfsspoc",
                        },
                      }),
                      _c("text-input", {
                        staticClass: "mb-6",
                        attrs: { label: "FSS Email" },
                        model: {
                          value: _vm.model.gainsIncomingfssemail,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "gainsIncomingfssemail", $$v)
                          },
                          expression: "model.gainsIncomingfssemail",
                        },
                      }),
                      _c("phone-input", {
                        staticClass: "mb-6",
                        attrs: { label: "FSS Phone" },
                        model: {
                          value: _vm.model.gainsIncomingfssphone,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "gainsIncomingfssphone", $$v)
                          },
                          expression: "model.gainsIncomingfssphone",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.model.gainsPoctype === _vm.PocType.RECRUITER
                ? _c(
                    "div",
                    { key: "Recruiter", staticClass: "form-input-container" },
                    [
                      _c("read-only-component", {
                        staticClass: "mb-6",
                        attrs: {
                          label: "Recruiter POC",
                          value: _vm.model.recruiterPocInformation.name,
                        },
                      }),
                      _c("read-only-component", {
                        staticClass: "mb-6",
                        attrs: {
                          label: "Recruiter Email",
                          value: _vm.model.recruiterPocInformation.email,
                        },
                      }),
                      _c("read-only-component", {
                        staticClass: "mb-6",
                        attrs: {
                          label: "Recruiter Phone",
                          value: _vm.model.recruiterPocInformation.phone,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "flex flex-col gap-2 mb-8" }, [
          _c("h3", { staticClass: "section-header" }, [_vm._v("Duty Status")]),
          _c(
            "div",
            {
              staticClass: "items-center form-input-container text-lato",
              class: _vm.isIMA && "block",
            },
            [
              _c("read-only-component", {
                attrs: { value: _vm.applicant.applicantType },
              }),
              _vm.showIRR
                ? _c("radio", {
                    attrs: {
                      label: "IRR (Individual Ready Reserve)",
                      radioItems: _vm.yesNoOptions,
                    },
                    model: {
                      value: _vm.model.priorServiceInactivereadyreservestatus,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.model,
                          "priorServiceInactivereadyreservestatus",
                          $$v
                        )
                      },
                      expression:
                        "model.priorServiceInactivereadyreservestatus",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("div", { staticClass: "h-full mt-3" }, [
            _vm.isRotc
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "mb-5 form-input-container" },
                    [
                      _c("date-selector-component", {
                        attrs: {
                          "data-cy": "rotcGraduation",
                          label: "Graduation Date",
                          errorText: "Please enter a date",
                        },
                        model: {
                          value:
                            _vm.model.academicInformationExpectedrotcgraduation,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.model,
                              "academicInformationExpectedrotcgraduation",
                              $$v
                            )
                          },
                          expression:
                            "model.academicInformationExpectedrotcgraduation",
                        },
                      }),
                      _c("radio", {
                        attrs: {
                          "data-cy": "Stipend",
                          label: "Stipend",
                          radioItems: _vm.yesNoOptions,
                        },
                        model: {
                          value: _vm.model.militaryStatusStipend,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "militaryStatusStipend", $$v)
                          },
                          expression: "model.militaryStatusStipend",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm.isTraditionalReservist
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "mb-5 form-input-container" },
                    [
                      _c("text-input", {
                        attrs: {
                          "data-cy": "dutyStationTextInput",
                          label: "Duty Station",
                          errorText: "Please enter the duty station",
                        },
                        model: {
                          value: _vm.model.priorServiceDutystation,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "priorServiceDutystation", $$v)
                          },
                          expression: "model.priorServiceDutystation",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm.isActiveGuardReserve
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "mb-5 form-input-container" },
                    [
                      _c("radio", {
                        attrs: {
                          label: "Curtailment Started",
                          radioItems: _vm.yesNoOptions,
                        },
                        model: {
                          value: _vm.model.militaryStatusIscurtailmentstarted,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.model,
                              "militaryStatusIscurtailmentstarted",
                              $$v
                            )
                          },
                          expression:
                            "model.militaryStatusIscurtailmentstarted",
                        },
                      }),
                      _c("date-selector-component", {
                        attrs: {
                          label: "Last Day of AGR Order",
                          errorText: "Please enter a date",
                        },
                        model: {
                          value: _vm.model.militaryStatusLastdayofagrorder,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.model,
                              "militaryStatusLastdayofagrorder",
                              $$v
                            )
                          },
                          expression: "model.militaryStatusLastdayofagrorder",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm.isActiveDuty
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "mb-5 form-input-container" },
                    [
                      _c("radio", {
                        attrs: { radioItems: _vm.activeDutyRadioOptions },
                        model: {
                          value: _vm.model.priorServicePalace,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "priorServicePalace", $$v)
                          },
                          expression: "model.priorServicePalace",
                        },
                      }),
                      _c("date-selector-component", {
                        attrs: {
                          label: "DOS (Date of Separation)",
                          errorText: "Please enter a date",
                        },
                        model: {
                          value:
                            _vm.model.militaryStatusEstimatedtimeofseparation,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.model,
                              "militaryStatusEstimatedtimeofseparation",
                              $$v
                            )
                          },
                          expression:
                            "model.militaryStatusEstimatedtimeofseparation",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm.isAirNationalGuard
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "mb-5 form-input-container" },
                    [
                      _c("radio", {
                        attrs: {
                          label: "Conditional Release Approved",
                          radioItems: _vm.yesNoOptions,
                        },
                        model: {
                          value:
                            _vm.model.priorServiceIsconditionalreleaseapproved,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.model,
                              "priorServiceIsconditionalreleaseapproved",
                              $$v
                            )
                          },
                          expression:
                            "model.priorServiceIsconditionalreleaseapproved",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm.isSisterService
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "mb-5 form-input-container" },
                    [
                      _c("dropdown-component", {
                        attrs: {
                          label: "Sister Service",
                          values: _vm.sisterServiceOptions,
                        },
                        model: {
                          value: _vm.model.priorServiceSisterservice,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.model,
                              "priorServiceSisterservice",
                              $$v
                            )
                          },
                          expression: "model.priorServiceSisterservice",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
      _c(
        "h3",
        {
          staticClass: "section-header",
          attrs: { id: _vm.sections.PRE_GAIN.key },
        },
        [_vm._v(" " + _vm._s(_vm.sections.PRE_GAIN.label) + " ")]
      ),
      _c(
        "div",
        { staticClass: "mb-10 form-input-container gap-y-6" },
        [
          _c("date-selector-component", {
            attrs: {
              "data-cy": "sharedDriveDate",
              label: "Shared drive folder",
            },
            model: {
              value: _vm.model.gainsShareddrivefolderdate,
              callback: function ($$v) {
                _vm.$set(_vm.model, "gainsShareddrivefolderdate", $$v)
              },
              expression: "model.gainsShareddrivefolderdate",
            },
          }),
          _c("date-selector-component", {
            attrs: { "data-cy": "1288SentDate", label: "1288 Sent" },
            model: {
              value: _vm.model.gains1288sentdate,
              callback: function ($$v) {
                _vm.$set(_vm.model, "gains1288sentdate", $$v)
              },
              expression: "model.gains1288sentdate",
            },
          }),
          _c("date-selector-component", {
            attrs: { "data-cy": "af1288Received", label: "AF 1288 Received" },
            model: {
              value: _vm.model.gainsAf1288received,
              callback: function ($$v) {
                _vm.$set(_vm.model, "gainsAf1288received", $$v)
              },
              expression: "model.gainsAf1288received",
            },
          }),
          _c("date-selector-component", {
            attrs: {
              "data-cy": "milpdsPasCodeConfirmationate",
              label: "MILPDS PAS Code Confirm",
            },
            model: {
              value: _vm.model.gainsMilpdspascodeconfirmationate,
              callback: function ($$v) {
                _vm.$set(_vm.model, "gainsMilpdspascodeconfirmationate", $$v)
              },
              expression: "model.gainsMilpdspascodeconfirmationate",
            },
          }),
          _c("date-selector-component", {
            attrs: {
              "data-cy": "vitalsCommCheckDate",
              label: "Vitals Comm Check",
            },
            model: {
              value: _vm.model.gainsVitalscommcheckdate,
              callback: function ($$v) {
                _vm.$set(_vm.model, "gainsVitalscommcheckdate", $$v)
              },
              expression: "model.gainsVitalscommcheckdate",
            },
          }),
          _c("date-selector-component", {
            attrs: {
              "data-cy": "milpdsGainProjectionDate",
              label: "MILPDS Gain Projection",
            },
            model: {
              value: _vm.model.gainsMilpdsgainprojectiondate,
              callback: function ($$v) {
                _vm.$set(_vm.model, "gainsMilpdsgainprojectiondate", $$v)
              },
              expression: "model.gainsMilpdsgainprojectiondate",
            },
          }),
          _c("date-selector-component", {
            attrs: { "data-cy": "securityCheckDate", label: "Security" },
            model: {
              value: _vm.model.gainsSecuritycheckdate,
              callback: function ($$v) {
                _vm.$set(_vm.model, "gainsSecuritycheckdate", $$v)
              },
              expression: "model.gainsSecuritycheckdate",
            },
          }),
          _c("dropdown-component", {
            attrs: {
              label: "Medical Clearance Status",
              values: _vm.medicalClearanceOptions,
            },
            model: {
              value: _vm.model.gainsMedicalClearanceStatus,
              callback: function ($$v) {
                _vm.$set(_vm.model, "gainsMedicalClearanceStatus", $$v)
              },
              expression: "model.gainsMedicalClearanceStatus",
            },
          }),
          _c("date-selector-component", {
            attrs: {
              "data-cy": "medicalClearanceDate",
              label: "Medical Clearance Date",
              disableDateEntry: _vm.disableMedicalClearanceDateEntry,
              readonly: _vm.disableMedicalClearanceDateEntry,
            },
            model: {
              value: _vm.model.gainsMedicalclearancedate,
              callback: function ($$v) {
                _vm.$set(_vm.model, "gainsMedicalclearancedate", $$v)
              },
              expression: "model.gainsMedicalclearancedate",
            },
          }),
        ],
        1
      ),
      _c(
        "h3",
        {
          staticClass: "section-header",
          attrs: { id: _vm.sections.POST_GAIN.key },
        },
        [_vm._v(" " + _vm._s(_vm.sections.POST_GAIN.label) + " ")]
      ),
      _c(
        "div",
        { staticClass: "mb-10 form-input-container gap-y-6" },
        [
          _c("date-selector-component", {
            attrs: { "data-cy": "gainDate", label: "Gain date updated" },
            model: {
              value: _vm.model.gainsGaindate,
              callback: function ($$v) {
                _vm.$set(_vm.model, "gainsGaindate", $$v)
              },
              expression: "model.gainsGaindate",
            },
          }),
          _c("date-selector-component", {
            attrs: { "data-cy": "afrSectionIdDate", label: "AFR Section ID" },
            model: {
              value: _vm.model.gainsAfrsectioniddate,
              callback: function ($$v) {
                _vm.$set(_vm.model, "gainsAfrsectioniddate", $$v)
              },
              expression: "model.gainsAfrsectioniddate",
            },
          }),
          _c("date-selector-component", {
            attrs: {
              "data-cy": "ddAformationDate",
              label: "DD Form 4/AF Form 133",
            },
            model: {
              value: _vm.model.gainsDdaformationdate,
              callback: function ($$v) {
                _vm.$set(_vm.model, "gainsDdaformationdate", $$v)
              },
              expression: "model.gainsDdaformationdate",
            },
          }),
          _c("date-selector-component", {
            attrs: { "data-cy": "surfDate", label: "SURF" },
            model: {
              value: _vm.model.gainsSurfdate,
              callback: function ($$v) {
                _vm.$set(_vm.model, "gainsSurfdate", $$v)
              },
              expression: "model.gainsSurfdate",
            },
          }),
          _c("date-selector-component", {
            attrs: { "data-cy": "dutyTitleDate", label: "Duty Title" },
            model: {
              value: _vm.model.gainsDutytitledate,
              callback: function ($$v) {
                _vm.$set(_vm.model, "gainsDutytitledate", $$v)
              },
              expression: "model.gainsDutytitledate",
            },
          }),
          _c("date-selector-component", {
            attrs: {
              "data-cy": "compCatConfirmedDate",
              label: "COMPCAT Confirmed",
            },
            model: {
              value: _vm.model.gainsCompcatconfirmeddate,
              callback: function ($$v) {
                _vm.$set(_vm.model, "gainsCompcatconfirmeddate", $$v)
              },
              expression: "model.gainsCompcatconfirmeddate",
            },
          }),
          _c("date-selector-component", {
            attrs: { "data-cy": "aefBanDate", label: "AEF Band" },
            model: {
              value: _vm.model.gainsAefbandate,
              callback: function ($$v) {
                _vm.$set(_vm.model, "gainsAefbandate", $$v)
              },
              expression: "model.gainsAefbandate",
            },
          }),
          _c("date-selector-component", {
            attrs: { "data-cy": "postActionDate", label: "Post Action" },
            model: {
              value: _vm.model.gainsPostactiondate,
              callback: function ($$v) {
                _vm.$set(_vm.model, "gainsPostactiondate", $$v)
              },
              expression: "model.gainsPostactiondate",
            },
          }),
        ],
        1
      ),
      _c(
        "h3",
        {
          staticClass: "section-header",
          attrs: { id: _vm.sections.PRIORITY.key },
        },
        [_vm._v(" " + _vm._s(_vm.sections.PRIORITY.label) + " ")]
      ),
      _c(
        "div",
        { staticClass: "mb-28 form-input-container gap-y-6" },
        [
          _c("table-drop-down-component", {
            attrs: {
              itemValue: _vm.model.gainsGainspriority,
              itemValues: _vm.gainsPriorityOptions,
            },
            on: { input: _vm.handleOptionChange },
          }),
        ],
        1
      ),
      _c("update-sticky-component", {
        attrs: { isUpdating: _vm.isUpdating },
        on: { click: _vm.updateApplicant },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }